import axios from 'axios'

async function openDatabase() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open("MangaDatabase", 2); // Updated version number

    request.onupgradeneeded = function (event) {
      const db = event.target.result;
      if (!db.objectStoreNames.contains("mangaChapters")) {
        db.createObjectStore("mangaChapters", { keyPath: ["series", "chapter"] });
      }
      // Create a new object store for series titles if it doesn't exist
      if (!db.objectStoreNames.contains("seriesTitles")) {
        db.createObjectStore("seriesTitles", { keyPath: 'title' });
      }
    };

    request.onsuccess = function (event) {
      resolve(event.target.result);
    };

    request.onerror = function (event) {
      reject(event.target.error);
    };
  });
}

async function titleExists(titleToCheck) {
  const db = await openDatabase(); // Open the database

  return new Promise((resolve, reject) => {
    const transaction = db.transaction('seriesTitles', 'readonly');
    const store = transaction.objectStore('seriesTitles');
    const request = store.openCursor(); // Open a cursor to iterate over all records

    request.onsuccess = function (event) {
      const cursor = event.target.result;
      if (cursor) {
        if (cursor.value.title === titleToCheck) {
          resolve(true); // Title found, resolve with true
          return; // Exit the function
        }
        cursor.continue(); // Move to the next record
      } else {
        resolve(false); // Reached the end without finding the title, resolve with false
      }
    };

    request.onerror = function (event) {
      reject(event.target.error);
    };
  });
}

async function saveSeriesTitle(title, chaptersCount, lastDownloaded, originUrl) {
  const db = await openDatabase();

  const transaction = db.transaction('seriesTitles', 'readwrite');
  const store = transaction.objectStore('seriesTitles');

  await store.put({ title, chaptersCount, currentChapter: 1, lastDownloaded, originUrl });

}

async function saveChapter(seriesName, chapterNumber, content) {
  const db = await openDatabase();
  const transaction = db.transaction("mangaChapters", "readwrite");
  const store = transaction.objectStore("mangaChapters");

  store.put({ series: seriesName, chapter: chapterNumber, content: content });

  return new Promise((resolve, reject) => {
    transaction.oncomplete = function () { resolve(); };
    transaction.onerror = function (event) { reject(event.target.error); };
  });
}

async function fetchWebsiteContent(url) {
  try {
    // Use your local server's proxy endpoint
    const proxyUrl = `/api/getHtml`;
    const response = await axios.post(proxyUrl, { url });
    return response.data; // Axios handles the response and returns the data
  } catch (error) {
    console.error('Error fetching website:', error);
  }
}

function parseHTML(html, url) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const links = [...doc.querySelectorAll('a')].map(a => a.href);
  const title = doc.querySelector('head > title')?.textContent || url

  return { links, title }
}

function parseImages(html) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const images = doc.querySelectorAll('img');

  return { images, doc }
}

async function fetchImage(url) {
  try {
    if (url) {
      const proxyUrl = `/api/getHtml`;
      const response = await axios.post(proxyUrl, { url, image: true });
      return response.data;
    }
  } catch (error) {
    console.error('Error fetching image:', error);
    return null;
  }
}

async function replaceImageSources(images, doc, stop) {
  for (const img of images) {
    if (stop.current?.active) return 'break'
    const source = img.src || img.getAttribute('data-src') || img.getAttribute('data-lazy-src');

    const dataUrl = await fetchImage(source);
    if (dataUrl) {
      img.src = dataUrl;
      img.style.width = '100%'
      // Remove attributes related to lazy loading
      img.removeAttribute('data-src');
      img.removeAttribute('data-lazy-src');
    }
  }

  return doc.documentElement.outerHTML;
}

export const getChaptersFromParsed = async (url) => {
  const htmlContent = await fetchWebsiteContent(url);
  if (htmlContent) {
    const { links, title } = parseHTML(htmlContent);
    const chaptersLinks = createChapterLinks(links);
    return { chaptersLinks, title }
  }
  return null
}

export async function downloadAndParseWebsite(url, chaptersAndTitle, setStatus, stop, restartIndex) {
  if(!chaptersAndTitle) return
  const { chaptersLinks, title } = chaptersAndTitle
  const isTitleExists = restartIndex == null && await titleExists(title)
  if (isTitleExists) return 'exists'
  const chaptersCount = chaptersLinks.length
  let lastDownloaded = 0
  const startIndex = restartIndex != null ? restartIndex + 1 : 0
  for (let index = startIndex; index < chaptersCount; index++) {
    if (stop.current?.active) break
    setStatus(`Chapter ${index + 1} / ${chaptersCount}`);
    const link = chaptersLinks[index];
    let content = await fetchWebsiteContent(link);
    const { images, doc } = parseImages(content, link);
    content = await replaceImageSources(images, doc, stop);
    if (content === 'break') break
    await saveChapter(title, index + 1, content);
    lastDownloaded = index
  }

  await saveSeriesTitle(title, chaptersCount, lastDownloaded, url);
  return { title, chaptersCount, lastDownloaded, originUrl: url };

}

export async function restartDownload(download, setStatus, stop) {
  const { originUrl, lastDownloaded } = download
  const restartIndex = lastDownloaded
  const chaptersAndTitle = await getChaptersFromParsed(originUrl)
  return await downloadAndParseWebsite(originUrl, chaptersAndTitle, setStatus, stop, restartIndex)
}
/////////////////
///GETTERS
/////////////////

export async function getAllSeriesNames() {
  try {
    const db = await openDatabase(); // Open the MangaDatabase
    const transaction = db.transaction('seriesTitles', 'readonly');
    const store = transaction.objectStore('seriesTitles');
    const seriesNames = new Set(); // Use a Set to collect unique names

    return new Promise((resolve, reject) => {
      const cursorRequest = store.openCursor();

      cursorRequest.onsuccess = function (event) {
        const cursor = event.target.result;
        if (cursor) {
          seriesNames.add(cursor.value); // Add series title to the Set
          cursor.continue();
        } else {
          resolve(Array.from(seriesNames)); // Convert Set to Array and resolve it
        }
      };

      cursorRequest.onerror = function (event) {
        reject(event.target.error);
      };

      // Handle transaction errors
      transaction.onerror = function (event) {
        reject(event.target.error);
      };
    });
  } catch (error) {
    console.error("Failed to open the database or read from it:", error);
    throw error; // Or handle this error as needed
  }
}


async function getAllChaptersForSeries(seriesName) {
  const db = await openDatabase();
  const transaction = db.transaction("mangaChapters", "readonly");
  const store = transaction.objectStore("mangaChapters");
  const chapters = [];

  return new Promise((resolve, reject) => {
    // Open a cursor to iterate over all records in the store
    const cursorRequest = store.openCursor();

    cursorRequest.onsuccess = function (event) {
      const cursor = event.target.result;
      if (cursor) {
        // Check if the series name matches
        if (cursor.value.series === seriesName) {
          chapters.push(cursor.value.chapter);
        }
        cursor.continue();
      } else {
        // No more entries, resolve the promise with the chapters array
        resolve(chapters);
      }
    };

    cursorRequest.onerror = function (event) {
      reject(event.target.error);
    };
  });
}


async function getChapter(seriesName, chapterNumber) {
  const db = await openDatabase();
  const transaction = db.transaction("mangaChapters", "readonly");
  const store = transaction.objectStore("mangaChapters");
  const request = store.get([seriesName, chapterNumber]);

  return new Promise((resolve, reject) => {
    request.onsuccess = function (event) {
      resolve(event.target.result);
    };
    request.onerror = function (event) {
      reject(event.target.error);
    };
  });
}

const messageHtml = `
        <html>
          <head>
            <style>
              body {
                color: white;
                height: 100vh;
                margin: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: Arial, sans-serif;
              }
            </style>
          </head>
          <body>
            <p>Chapter not downloaded</p>
          </body>
        </html>`;

export function displayChapter(seriesName, chapterNumber) {
  getChapter(seriesName, chapterNumber).then(htmlContent => {
    const viewer = document.getElementById('viewer');
    if (htmlContent?.content) {
      const blob = new Blob([htmlContent.content], { type: 'text/html' });
      const blobUrl = URL.createObjectURL(blob);
      viewer.src = blobUrl;
    } else {
      const messageBlob = new Blob([messageHtml], { type: 'text/html' });
      const messageBlobUrl = URL.createObjectURL(messageBlob);
      viewer.src = messageBlobUrl;
    }
    updateCurrentChapter(seriesName, chapterNumber).catch(console.error);
  }).catch(error => {
    console.error('Error retrieving offline content:', error);
  });
}
async function updateCurrentChapter(seriesName, chapterNumber) {
  const db = await openDatabase(); // Open the MangaDatabase
  const transaction = db.transaction('seriesTitles', 'readwrite');
  const store = transaction.objectStore('seriesTitles');

  return new Promise((resolve, reject) => {
    // Open a cursor to iterate over all records in the store
    const cursorRequest = store.openCursor();

    cursorRequest.onsuccess = function (event) {
      const cursor = event.target.result;
      if (cursor) {
        // Check if the series name matches
        if (cursor.value.title === seriesName) {
          const updatedData = cursor.value;
          updatedData.currentChapter = chapterNumber;
          const updateRequest = cursor.update(updatedData); // Update the record

          updateRequest.onsuccess = function () {
            resolve();
          };

          updateRequest.onerror = function (event) {
            reject('Error updating the chapter:', event.target.error);
          };
        } else {
          cursor.continue();
        }
      } else {
        resolve(); // Resolve the promise if no more entries
      }
    };

    cursorRequest.onerror = function (event) {
      reject('Error fetching series:', event.target.error);
    };
  });
}

export async function getAllSavedUrls() {
  const db = await openDatabase();
  const transaction = db.transaction("mangaChapters", "readonly");
  const store = transaction.objectStore("mangaChapters");
  const request = store.getAllKeys();

  return new Promise((resolve, reject) => {
    request.onsuccess = function () { resolve(request.result); };
    request.onerror = function (event) { reject(event.target.error); };
  });
}
///////////
/////DELETE functions
//////////

export async function deleteSeries(seriesName) {
  const db = await openDatabase();

  // Delete all chapters associated with the series
  await new Promise((resolve, reject) => {
    const chaptersTransaction = db.transaction("mangaChapters", "readwrite");
    const chaptersStore = chaptersTransaction.objectStore("mangaChapters");
    const chaptersRequest = chaptersStore.openCursor();

    chaptersRequest.onsuccess = (event) => {
      const cursor = event.target.result;
      if (cursor) {
        if (cursor.value.series === seriesName) {
          chaptersStore.delete(cursor.primaryKey);
        }
        cursor.continue();
      } else {
        resolve(); // All related chapters have been processed
      }
    };

    chaptersRequest.onerror = (event) => {
      reject(event.target.error);
    };
  });

  // Delete the series title
  await new Promise((resolve, reject) => {
    const titleTransaction = db.transaction("seriesTitles", "readwrite");
    const titlesStore = titleTransaction.objectStore("seriesTitles");
    const titleRequest = titlesStore.openCursor();

    titleRequest.onsuccess = (event) => {
      const cursor = event.target.result;
      if (cursor) {
        if (cursor.value.title === seriesName) {
          titlesStore.delete(cursor.primaryKey);
          resolve(); // Title found and deleted
          return;
        }
        cursor.continue();
      } else {
        resolve(); // End of store reached without finding the title
      }
    };

    titleRequest.onerror = (event) => {
      reject(event.target.error);
    };
  });
}

//REGEX and links
function generateRegexFromUrls(url1, url2) {
  if (url1 === url2) return /(.*(?:chapter|chapitre)-)(\d+)/
  let commonPart = "";
  let index = 0;

  for (index = 0; index < Math.min(url1.length, url2.length); index++) {
    if (url1[index] !== url2[index]) {
      break;
    }
    commonPart += url1[index];
  }

  let precedingNumberPart = "";
  while (index > 0 && /\d/.test(url1[index - 1])) {
    precedingNumberPart = url1[index - 1] + precedingNumberPart;
    index--;
  }

  if (precedingNumberPart) {
    commonPart = commonPart.slice(0, -precedingNumberPart.length);
  }

  const pattern = `(${commonPart})(\\d+)`;
  return pattern;
}



function getMaxChapter(urls, regex) {
  const chapterNumbers = urls
    .map(url => {
      const match = url.match(regex);
      return match ? parseInt(match[2], 10) : null;
    })
    .filter(number => number !== null); // Filter out null values
  return Math.max(...chapterNumbers);
}


function generateChapterUrls(urls, regex, maxChapter) {
  let newUrls = [];
  let template = findMostCommonRegex(urls);
  for (let i = 1; i <= maxChapter; i++) {
    let newUrl = template.replace(regex, (match, p1, p2) => {
      return `${p1}${i.toString()}`
    });
    newUrls.push(newUrl);
  }

  return newUrls;
}


function countRegexOccurrences(regexes) {
  const countMap = new Map();

  regexes.forEach(regex => {
    const regexStr = regex.toString();
    if (countMap.has(regexStr)) {
      countMap.set(regexStr, countMap.get(regexStr) + 1);
    } else {
      countMap.set(regexStr, 1);
    }
  });

  return countMap;
}

function findMostCommonRegex(regexes) {
  const countMap = countRegexOccurrences(regexes);
  let mostCommonRegex = null;
  let maxCount = 0;

  countMap.forEach((count, regexStr) => {
    if (count > maxCount) {
      maxCount = count;
      mostCommonRegex = regexStr;
    }
  });

  return mostCommonRegex ? mostCommonRegex : null;
}


const createChapterLinks = (links) => {
  let chaptersLinks = links.filter(link => link.includes('chapter') || link.includes('chapitre'))
  const regexes = []
  if (chaptersLinks.length > 1) {
    for (let i = 0; i < chaptersLinks.length - 1; i += 2) {
      regexes.push(generateRegexFromUrls(chaptersLinks[i], chaptersLinks[i + 1]))
    }
  }
  else regexes.push(generateRegexFromUrls(chaptersLinks[0], chaptersLinks[0]))
  let regex = findMostCommonRegex(regexes)
  regex = new RegExp(regex)
  chaptersLinks = chaptersLinks.filter(link => regex.test(link))
  const maxChapter = getMaxChapter(chaptersLinks, regex)
  return generateChapterUrls(chaptersLinks, regex, maxChapter)
}


/////////
///UTILS
/////////

export async function estimateSizeOfIndexedDB(dbName, storeName) {
  const db = await openDatabase(dbName);
  const transaction = db.transaction(storeName, 'readonly');
  const store = transaction.objectStore(storeName);

  return new Promise((resolve, reject) => {
    const request = store.getAll();
    request.onsuccess = function () {
      const result = request.result;
      const serialized = JSON.stringify(result);
      const sizeInBytes = new TextEncoder().encode(serialized).length;
      resolve(sizeInBytes);
    };
    request.onerror = function (event) {
      reject(event.target.error);
    };
  });
}
