import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import styled from 'styled-components'
import { downloadAndParseWebsite, getAllSeriesNames, displayChapter, deleteSeries, restartDownload, getChaptersFromParsed } from './indexedDB/store'
import { IoMdClose } from "react-icons/io";
import { IoArrowBackOutline, IoArrowForwardOutline, IoCloudDone, IoCloudDownloadSharp } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import Loader, { Background } from './library/loader'
import theme from './theme.json'

const Root = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  background: ${theme.black};
  `
const Title = styled.div`
  padding-top: 20px;
  font-weight: bold;
  font-size: 2rem;
  color: ${theme.white};
  `
const Input = styled.input`
  border: 1px solid ${theme.grey};
  border-radius: 5px;
  width: 80%;
  height: 35px;
  padding-left: 5px;
  background: ${theme.black};
  color: ${theme.primary};
  &:focus {
   outline: none;
   border: 1px solid ${theme.primary};
 }
`

const Button = styled.div`
  height: 30px;
  width: fit-content;
  background: ${theme.primary};
  color: ${theme.white};
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`



function App() {
  const [url, setUrl] = useState("")
  const [downloads, setDownloads] = useState([])
  const [loading, setLoading] = useState(false)
  const stop = useRef()
  const [isReading, setIsReading] = useState(false)
  const [chapterChoice, setChapterChoice] = useState(false)
  const [chaptersCount, setChaptersCount] = useState(0)
  const chaptersAndTitle = useRef()

  const handleChange = (event) => {
    setUrl(event.target.value)
  }


  const initDownload = async () => {
    chaptersAndTitle.current = await getChaptersFromParsed(url)
    const { chaptersLinks } = chaptersAndTitle.current
    setChaptersCount(chaptersLinks?.length)
    setChapterChoice(true)
  }

  const download = async (startIndex) => {
    setChapterChoice(false)
    if(startIndex === 'close') return
    setLoading(true)
    stop.current = { active: false }
    const download = await downloadAndParseWebsite(url, chaptersAndTitle.current, setLoading, stop, startIndex)
    if (download) {
      if (download !== 'exists')
        setDownloads([...downloads, download])
    }
    setLoading(false)
    stop.current = { active: false }
  }

  const stopDownload = () => {
    stop.current = { active: true }
  }

  useEffect(() => {
    const loadSeriesNames = async () => {
      let names = await getAllSeriesNames()
      setDownloads(names)
    }
    loadSeriesNames()
  }, [])

  return (
    <Root>
      {
        !isReading &&
        <>
          <Title>
            DIANEUH!
          </Title>
          <Input
            placeholder="Entrer un URL..."
            onChange={handleChange}
          />
          <Button onClick={initDownload}>
            Download !
          </Button>
        </>
      }
      <DownloadedList
        isReading={isReading}
        setIsReading={setIsReading}
        downloads={downloads}
        setDownloads={setDownloads}
        setLoading={setLoading}
        stop={stop}
      />
      {chapterChoice && <ChapterChoice chaptersCount={chaptersCount} selectChapter={download} />}
      {loading !== false && <Loader status={loading} stop={stopDownload} />}
    </Root>
  );
}

const ChapterChoiceContainer = styled.div`
  position: absolute;
  height: fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  flex-direction: column;
  border-radius: 5px;
  padding: 15px;
  background-color: ${theme.grey};
  color: ${theme.white};
  gap: 15px;
`
const ChapterChoiceButton = styled.div`
  height: 30px;
  width: 30%;
  background: ${theme.primary};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2px;
`

const ChapterChoice = ({ chaptersCount, selectChapter }) => {
  const [options, setOptions] = useState([])

  useEffect(() => {
    const arr = Array.from({ length: chaptersCount })
    setOptions(arr)
  }, [chaptersCount])

  const chooseChapter = (event) => {
    if (event) {
      let index = event.target.value
      index -= 2
      selectChapter(parseInt(index))
    }
  }
  return (
    <>
      <Background onClick={() => selectChapter('close')} />
      <ChapterChoiceContainer>
        Choose the chapters to download
        <Chapter onChange={chooseChapter}>
          <option selected key={`chapterChoice-s`} value={'Select'}>- Select start -</option>
          {options.map((_, index) => (
            <option key={`chapterChoice-${index}`} value={index + 1}>{index + 1}</option>
          ))}
        </Chapter>
        or
        <ChapterChoiceButton onClick={() => selectChapter()}>
          All
        </ChapterChoiceButton>
      </ChapterChoiceContainer>
    </>
  )
}

const ListContainer = styled.div`
  height: 70%;
  width: 80%;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid ${theme.grey};
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  overflow-x: hidden;
  overflow-y: auto;
`
const ContainerList = styled.div`
  min-height: 40px;
  height: fit-content;
  padding: 10px;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: ${theme.lightGrey};
`
const ListItem = styled.div`
  min-height: 40px;
  height: fit-content;
  padding: 10px;
  width: 70%;
  color: ${theme.primary};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Divider = styled.div`
  height: 0;
  width: 80%;
  border-bottom : 1px solid ${theme.grey};
`
const PlaceHolderList = styled.div`
  height: 100%;
  width: 100%;
  color: ${theme.grey};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: italic;
`
const DownloadedList = ({ downloads = [], setDownloads, isReading, setIsReading, setLoading, stop }) => {
  const [serieName, setSerieName] = useState('')

  const openSite = (name) => {
    setIsReading(true)
    setSerieName(name)
  }

  const deleteDB = (name) => {
    deleteSeries(name)
    setDownloads(downloads.filter(download => download.title !== name))
  }

  const continueDownload = async (download) => {
    setLoading(true)
    stop.current = { active: false }
    const updatedDownload = await restartDownload(download, setLoading, stop)
    download.lastDownloaded = updatedDownload.lastDownloaded
    setDownloads([...downloads])
    setLoading(false)
  }

  return (
    <>
      <ListContainer>
        {downloads.length > 0 ?
          downloads.map(download =>
            <React.Fragment key={download.title}>
              <ContainerList>
                {
                  download.lastDownloaded === download.chaptersCount ?
                    <IoCloudDone
                      style={{
                        transform: 'scale(1.2)',
                        color: theme.green
                      }} /> :
                    <IoCloudDownloadSharp
                      style={{
                        transform: 'scale(1.2)',
                        color: theme.orange
                      }}
                      onClick={() => continueDownload(download)}
                    />
                }
                <ListItem onClick={() => openSite(download)}>
                  {download.title}
                </ListItem>
                <MdDelete style={{ width: 22, height: 22, color: theme.lightGrey }} onClick={() => deleteDB(download.title)} />
              </ContainerList>
              <Divider />
            </React.Fragment>
          ) :
          <PlaceHolderList>
            Pas de site téléchargé
          </PlaceHolderList>
        }
      </ListContainer>
      <Reader isReading={isReading} setIsReading={setIsReading} serieName={serieName} />
    </>
  )
}
const ReaderContainer = styled.div`
  display: ${props => props.isReading ? 'flex' : 'none'};
  flex-direction: column;
  position: absolute;
  height: 100vh;
  width: 95vw;
`
const Viewer = styled.iframe`
  height: 100%;
  width: 100%;
  background: ${theme.black};
  border: 1px solid ${theme.grey};
`
const Close = styled.div`
  height: 25px;
  width: 25px;
  color: ${theme.white};
  border: 2px solid ${theme.primary};
  background: ${theme.primary};
  border-radius: 25px;
  cursor: pointer;
  position: absolute;
  top:1.5vh;
  left:1vh;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Chapter = styled.select`
  height: 30px;
  border: none;
  color: ${theme.white};
  background: ${theme.black};
  font-size: 1.1rem;
`

const ChapterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 25px;
  min-height: 50px;
  color: ${theme.white};
`
const Reader = ({ isReading, setIsReading, serieName }) => {
  const [currentChapter, setCurrentChapter] = useState(null)
  const [options, setOptions] = useState([])

  const close = () => {
    setIsReading(false)
    setCurrentChapter(null)
  }

  const changeChapter = (direction) => {
    let chapter = currentChapter || serieName.currentChapter || 1
    if (direction?.target) {
      chapter = parseInt(direction.target.value)
    }
    else {
      const p = direction === 'next' ? 1 : -1
      if (chapter + p > 0)
        chapter = chapter + p
    }
    setCurrentChapter(chapter)
    serieName.currentChapter = chapter
  }

  useEffect(() => {
    if (isReading && serieName) {
      const chapter = currentChapter || serieName.currentChapter || 1
      displayChapter(serieName.title, chapter, 'viewer')
      const arr = Array.from({ length: serieName.chaptersCount })
      arr[chapter] = true
      setOptions(arr)
    }
  }, [currentChapter, isReading])

  return (
    <ReaderContainer isReading={isReading}>
      <ChapterContainer>
        <IoArrowBackOutline style={{ transform: 'scale(1.4)' }} onClick={() => changeChapter('previous')} />
        <Chapter onChange={changeChapter}>
          {options.map((chap, index) => (
            <option selected={chap} key={`chapter-${index}`} value={index}>{index}</option>
          ))}
        </Chapter>
        <IoArrowForwardOutline style={{ transform: 'scale(1.4)' }} onClick={() => changeChapter('next')} />
      </ChapterContainer>
      <Viewer id='viewer' sandbox="allow-scripts" />
      {
        isReading &&
        <Close onClick={close}>
          <IoMdClose style={{ transform: 'scale(1.2)' }} />
        </Close>
      }
    </ReaderContainer>
  )
}


export default App;
