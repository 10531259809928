import React, { useState, useEffect } from 'react';
import DianeLoader from '../images/dianeLoad.png'
import styled, { keyframes } from 'styled-components'
import theme from '../theme.json'

const rotate = keyframes`
  from {
    transform: scale(0.35) rotate(0deg);
  }
  to {
    transform: scale(0.35) rotate(360deg);
  }
`;
export const Background = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0px;
  left: 0px;
  background: ${theme.grey};
  opacity: 0.5;
`
const Container = styled.div`
  height: 100px;
  width: 100px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: calc( 50vh - 50px);
  left: calc( 50vw - 50px);
  overflow: hidden;
  border: ${props => props.stopping && '4px solid' + theme.secondary}
`

const RotatingImage = styled.img`
  animation: ${rotate} 4s linear infinite;
`;
const Status = styled.div`
  position: absolute;
  top: 57%;
  font-size: 1.3rem;
  color: ${theme.white};
`;

export default function Loader({status = null, stop}){
  const [stopping, setStopping] = useState(false)

  useEffect(() => {
    return () => setStopping(false)
  },[])

  return(
    <>
      <Background />
      <Container stopping={stopping && stopping} onClick={() => {
        stop()
        setStopping(true)
      }}>
        <RotatingImage style={{transform: 'scale(0.35)'}} src={DianeLoader} alt="Diane Loading" />
      </Container>
      <Status>{status && status !== true && status}</Status>
    </>
  )
}
